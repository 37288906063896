import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  menuDrawer: {},
  showEditModal: false,
  tableFields: {},
  tableFieldsRj: {},
  tableFieldsProgram: {},
  currentPage: 1,
  searchData: {},
  searchStatus: "idle",
  searchKey: "",
  isLogged: false,
  dashboard_permissions: {},
  dashboard_permissions_status: "idle",
  isNewNotification: false,
  programsData: [],
  genresData: [],
};

export const getGlobalSearchData = createAsyncThunk(
  "global/getGlobalSearchData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/global-search?search=${params?.searchKey}&page_size=5&page=${params?.page}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMenuPermissions = createAsyncThunk(
  "global/getMenuPermissions",
  async (body, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/admin/role/menu-list`, body);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const globalSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMenuPermissions.pending, (state) => {
      state.dashboard_permissions_status = "pending";
    });
    builder.addCase(getMenuPermissions.fulfilled, (state, action) => {
      state.dashboard_permissions = action.payload.data.data;
      state.dashboard_permissions_status = "fulfilled";
      localStorage.setItem(
        "dashboard_permissions",
        JSON.stringify(action.payload.data.data)
      );
    });
    builder.addCase(getMenuPermissions.rejected, (state) => {
      state.dashboard_permissions_status = "rejected";
    });

    builder.addCase(getGlobalSearchData.pending, (state, action) => {
      state.searchStatus = "pending";
    });
    builder.addCase(getGlobalSearchData.fulfilled, (state, action) => {
      if (state.searchData && !action.meta?.arg?.clearState) {
        if (action.payload?.data?.data) {
          state.searchData = {
            ...action.payload?.data,
            searchStatus: "fulfilled",
            data: [...state.searchData.data, ...action.payload?.data?.data],
          };
        } else {
          state.searchData = {
            ...action.payload?.data,
            searchStatus: "fulfilled",
            data: [...state.searchData.data],
          };
        }
      } else {
        state.searchData = {
          ...action.payload?.data,
          searchStatus: "fulfilled",
          page: 1,
        };
      }
      state.searchStatus = "fulfilled";
    });
    builder.addCase(getGlobalSearchData.rejected, (state, action) => {
      state.searchStatus = "failed";
    });
  },
});

export const { updateConfig } = globalSlice.actions;

export default globalSlice.reducer;
