import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../../../store/slices/HomeDashboard/homeDashboardSlice";
import { updateConfig } from "../../../../../store/slices/TopPrograms/topProgramsSlice";
// import { updateConfig } from "../../../store/slices/HomeDashboard/homeDashboardSlice";
import Style from "./episodeList.module.scss";
import { DataContainer, Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../../../assets/Assets";
import { Chart } from "react-chartjs-2";
import { useGetEpisodeListDataQuery } from "../../../../../store/queries/topPrograms";
import { getFormatedDate } from "../../../../../utils/functions/table";
import { SelectWithIcons } from "../../../../Global/SelectWithIcons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Charts from "../../../../Global/Charts";


const useEpisodeList = () => {
  const topEpisodeState = useSelector((state) => state.topPrograms);
  const { tableEpisodes } = useSelector((state) => state.homeDashboard);
  const dashboardState = useSelector((state) => state.homeDashboard);
  const dispatch = useDispatch();

  const [searchCriteria, setSearchCriteria] = useState("");

  const refMenu = useRef(null);

  const checkTop = (id) =>
    refMenu[id]?.getBoundingClientRect().top > window.innerHeight / 2;

  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({});
  const [graphData, setGraphData] = useState({});
  const [count, setCount] = useState(0);
  const [sortBy, setSortBy] = useState("name"); // Default sorting field
  const [sortOrder, setSortOrder] = useState("asc"); // Default sorting order


  const handleViewTooltip = (e, data) => {
    setGraphData(data?.heat_map);
    setShowTooltip(true);
    const pageWidth = window.innerWidth / 1.5;
    const pageHeight = window.innerHeight / 1.5;
    const currectElement = e?.currentTarget?.getBoundingClientRect();
    const mousePostionX = currectElement?.left;
    const mousePostionY = currectElement?.top;
    setTooltipPosition({
      x: mousePostionX,
      y: mousePostionY,
      placementY: mousePostionY <= pageHeight ? "bottom" : "top",
      placementX: mousePostionX <= pageWidth ? "left" : "right",
    });
  };

  const handleHideTooltip = () => {
    setTooltipPosition({});
    setShowTooltip((prev)=> !prev);
    setGraphData({});
  };

  const {
    data: episodeListData = {},
    isFetching,
    isSuccess,
    refetch
  } = useGetEpisodeListDataQuery({
    length: topEpisodeState.episodeCurrentPageSize,
    from_date: getFormatedDate(topEpisodeState?.startDate),
    to_date: getFormatedDate(topEpisodeState?.endDate),
    list_type: "program",
    media_type: topEpisodeState?.media_type,
    _id: topEpisodeState?.program_id
  });

  const filteredData = episodeListData?.data?.list?.filter((item) => {
    const lowerCaseCriteria = searchCriteria.toLowerCase();
    return (
      item?.title?.toLowerCase().includes(lowerCaseCriteria)
    );
  });

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];


  const tableHeading = {
    title: { label: "Name", sortable: true },
    episode_number: { label: "Episode", sortable: true },
    total_duration: { label: "Total Duration", sortable: true } ,
    play_time: { label: "Play Time", sortable: true, tooltip:  "Total time spent watching or listening to a episode" }, 
    drop_off_time: { label: "Drop Off Time", sortable: true, tooltip:  "The point in time during a video or podcast when users typically stop watching or listening." }, 
    reach: { label: "Reach", sortable: true, tooltip:  "Total number of unique users who have viewed your episode." }, 
    plays: { label: "Plays", sortable: true, tooltip:  "The number of times this episode has been watched or listened to." }, 
    retention_rate: { label: "Retention Rate", sortable: true, tooltip:  "The percentage of the Users who return to the content after their initial visit" }, 
    liked_count : { label: "Liked Count", sortable: true, tooltip:  "The number of likes this episode has received." }, 
    unliked_count : { label: "Unliked Count", sortable: true, tooltip:  "The number of dislikes this episode has received." },
  };

  const sortedData = useMemo(() => {
    if (!episodeListData?.data?.list) return [];
  
    return [...episodeListData.data.list].sort((a, b) => {
      let valA = a[sortBy];
      let valB = b[sortBy];
  
      // Convert retention_rate to number for proper sorting
      if (sortBy === "retention_rate") {
        valA = parseFloat(valA);
        valB = parseFloat(valB);
      }
  
      // Convert play time fields to seconds (if applicable)
      if (sortBy === "total_play_time" || sortBy === "average_watch_time") {
        const timeToSeconds = (time) => {
          const parts = time.split(":").map(Number);
          return parts[0] * 3600 + parts[1] * 60 + parts[2];
        };
        valA = timeToSeconds(valA);
        valB = timeToSeconds(valB);
      }
  
      if (typeof valA === "string" && typeof valB === "string") {
        return sortOrder === "asc" ? valA.localeCompare(valB) : valB.localeCompare(valA);
      }
  
      return sortOrder === "asc" ? valA - valB : valB - valA;
    });
  }, [episodeListData, sortBy, sortOrder]);

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableEpisodes = tableHeading;
      })
    );
    // eslint-disable-next-line
  }, []);

   // Function to handle sorting
   const handleSort = (field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setSortOrder("asc");
    }
  };

  // const handleSort = (label) => {
  //   if (topEpisodeState.episodeSortBy === label) {
  //     dispatch(
  //       updateConfig((state) => {
  //         state.episodeCurrentPage = 1;
  //         state.episodeSortOrder = topEpisodeState.episodeSortOrder === 0 ? 1 : 0;
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       updateConfig((state) => {
  //         state.episodeCurrentPage = 1;
  //         state.episodeSortBy = label;
  //         state.episodeSortOrder = topEpisodeState.episodeSortOrder === 0 ? 1 : 0;;
  //       })
  //     );
  //   }
  // };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.episodeCurrentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.episodeCurrentPageSize = page_size;
        state.episodeCurrentPage = 1;
      })
    );
  };



  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => (
        <p className="pro-mb-0">{data[feild] ? `${data[feild]}` : "-"}</p>
      ),
      title: (feild, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
          >
            <div
              className={`pro-overflow-hidden pro-rounded-2 pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.thumbnail !== "" && data?.thumbnail
                    ? data?.thumbnail
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.title}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center `}
            >
              {data?.[feild]}
            </span>
          </div>
        );
      },
      total_duration: (feild, data) => (
        <p className="pro-mb-0">{data[feild] !== 0 ? `${data[feild]}` : 0}</p>
      ),
      play_time: (feild, data) => (
        <p className="pro-mb-0">{data[feild] !== 0 ? `${data[feild]}` : 0}</p>
      ),

      // drop_off_time: (feild, data) => (
      //   <p className="pro-mb-0">{data[feild] !== 0 ? `${data[feild]}` : 0}</p>
      // ),

      drop_off_time: (feild, data) => (
        <p
          className={`pro-mb-0 ${Style.user_select}`}
          ref={(ref) => (refMenu[data?.module_id] = ref)}
        >
              <SelectWithIcons
                menuPlacement={"top"}
                menuPosition="auto"
                handleViewTooltip={handleViewTooltip}
                handleHideTooltip={handleHideTooltip}
                setShowTooltip={setShowTooltip}
                showTooltip={showTooltip}
                count={count}
                setCount={setCount}
                data={data}
              />
        </p>
      ),

      reach: (feild, data) => (
        <p className="pro-mb-0">{data[feild] !== 0 ? `${data[feild]}` : 0}</p>
      ),
      plays: (feild, data) => (
        <p className="pro-mb-0">{data[feild] !== 0 ? `${data[feild]}` : 0}</p>
      ),
      retention_rate: (feild, data) => (
        <p className="pro-mb-0">{data[feild] !== 0 ? `${data[feild]}` : 0}</p>
      ),
      liked_count: (feild, data) => (
        <p className="pro-mb-0">{data[feild] !== 0 ? `${data[feild]}` : 0}</p>
      ),
      unliked_count: (feild, data) => (
        <p className="pro-mb-0">{data[feild] !== 0 ? `${data[feild]}` : 0}</p>
      ),
      
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };


  return {
    handleSort,
    getRow,
    tableEpisodes,
    filteredData,
    paginationOptions,
    handlePagination,
    handlePageSize,
    currentPage: topEpisodeState.episodeCurrentPage,
    topEpisodeState,
    episodeListData,
    showTooltip,
    tooltipPosition,
    graphData,
    setShowTooltip,
    count,
    setCount,
    handleHideTooltip,
    isFetching,
    sortedData,
  };
};

export default useEpisodeList;
