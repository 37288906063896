import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
// import { da } from "date-fns/locale";
// import Orders from "../../Orders/orderListing";
import { useGetFireBaseDashboardListDataQuery, useGetHomeDashboardListDataQuery, useGetHomeDashboardAppAnalyticsListDataQuery, useGetFireBaseDashboardAppAnalyticsListDataQuery, useGetChartDataQuery } from "../../../store/queries/homeDashboard";
import { chartData, updateConfig } from "../../../store/slices/HomeDashboard/homeDashboardSlice";
import { updateConfig as genresUpdateConfig } from "../../../store/slices/TopGenres/topGenresSlice";
import { updateConfig as programUpdateConfig } from "../../../store/slices/TopPrograms/topProgramsSlice";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// import Orders from "../../Orders";
// import { updateConfig } from "../../../store/slices/Orders/ordersSlice";
// import Transactions from "../../Transactions";
import Assets from "../../../assets/Assets";
import { getFormatedDate, getFormatedDateRange } from "../../../utils/functions/table";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { exportAllData } from "../../../store/slices/HomeDashboard/homeDashboardSlice";
import { toast } from "react-toastify";
import moment from "moment";

ChartJS.register(ArcElement, Tooltip, Legend);


const useHomeDashboardListing = () => {
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("activeTab");
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.homeDashboard);
  const topProgramState = useSelector((state) => state.topPrograms);
  const topGenresState = useSelector((state) => state.topGenres);

  const programsData = useSelector((state) => state.global.programsData);
  const genresData = useSelector((state) => state.global.genresData);
  const combinedData = [
    ...programsData.map((item) => ({ ...item, type: "program" })),
    ...genresData.map((item) => ({ ...item, type: "genre" }))
  ];
  
  const {
    data: fireBasedData = {},
    isFetching: isFireBasedDataFetching,
    // isSuccess: isfireBasedDataIsSuccess,
  } = useGetFireBaseDashboardListDataQuery({
    from_date: getFormatedDate(menuState?.startDate),
    to_date: getFormatedDate(menuState?.endDate),
  });
  const {
    data: dashboardData = {},
    isFetching,
    // isSuccess,
  } = useGetHomeDashboardListDataQuery({
    from_date: getFormatedDate(menuState?.startDate),
    to_date: getFormatedDate(menuState?.endDate),
    dashboard_type : activeTab === "app_analytics" ? 1 : 2,
  });

  const {
    data: appAnalyticsDashboardData = {},
    // isFetching,
    isSuccess,
  } = useGetHomeDashboardAppAnalyticsListDataQuery({
    from_date: getFormatedDate(menuState?.startDate),
    to_date: getFormatedDate(menuState?.endDate),
    // dashboard_type : activeTab === "app_analytics" ? 1 : 2,
    dashboard_type : 1,
  });

  const {
    data: appAnalyticsFirebaseData = {},
    isFetching :isFetchingAppAnalyticsFirebaseData,
    // isSuccess,
  } = useGetFireBaseDashboardAppAnalyticsListDataQuery({
    from_date: getFormatedDate(menuState?.startDate),
    to_date: getFormatedDate(menuState?.endDate),
    // dashboard_type : activeTab === "app_analytics" ? 1 : 2,
    dashboard_type : 1,
  });

  const {
    data: chartDataNew = {},
    isFetching :isFetchingChartDataNew,
    // isSuccess,
    } = useGetChartDataQuery({
    filter_type: menuState?.filter_type,
    data_type: menuState?.data_type,
    from_date: getFormatedDate(menuState?.startDate),
    to_date: getFormatedDate(menuState?.endDate),
    // dashboard_type : activeTab === "app_analytics" ? 1 : 2,
    dashboard_type : 1,
    });

  const lineChartDataNew =(filterType,dataType)=>{
    dispatch(updateConfig((state) => {
      state.data_type = dataType;
      state.filter_type = filterType;
    }));
  }

  useEffect(() => {
    dispatch(updateConfig((state) => {
      state.activeTab = activeTab
      state.dashboard_type = activeTab === "app_analytics" ? 1 : 2
    }));
    dispatch(programUpdateConfig((state) => {
      state.startDate = menuState?.startDate
      state.endDate = menuState?.endDate
    }));
    dispatch(genresUpdateConfig((state) => {
      state.startDate = menuState?.startDate
      state.endDate = menuState?.endDate
    }));
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  
  const totals = dashboardData?.data?.totals;

  // const keysToKeep = ["total_users", "active_users", "total_plays", "total_plays", "total_plays"];
  const keysToKeep = ["videos", "podcasts", "shorts", "total_plays"];

  const filteredTotals =
    totals &&
    Object.keys(totals)
      .filter((key) => keysToKeep.includes(key))
      .reduce((obj, key) => {
        obj[key] = totals[key];
        return obj;
      }, {});

      let dashboardItems = {
        total_users: {
          label: "total_users",
          title: "New Registered Users",
          icon: Assets.USERS_GROUP,
        },
        active_users: {
          label: "active_users",
          title: "Active Users",
          icon: Assets.ACTIVE_USER,
        },
        total_plays: {
          label: "total_plays",
          title: "Total Plays",
          icon: Assets.VIDEOS,
        },
        total_plays: {
          label: "total_plays",
          title: "Session Revisit",
          icon: Assets.VIDEOS,
        },
        total_plays: {
          label: "total_plays",
          title: "Average Attrition Rate",
          icon: Assets.VIDEOS,
        },
        // podcasts: {
        //   label: "podcasts",
        //   title: "Podcasts",
        //   icon: Assets.PODCASTS
        // },
        // shorts: {
        //   label: "shorts",
        //   title: "Shorts",
        //   icon: Assets.SHORTS,
        // },
      };

      
  const barData = useMemo(() => {
    return {
      labels: dashboardData?.data?.topCountries?.labels,
      datasets:[
        {
          label: "",
          data: dashboardData?.data?.topCountries?.data,
          backgroundColor: [
            'rgba(245, 158, 11, 1)',
            'rgba(245, 158, 11, 1)',
            'rgba(245, 158, 11, 1)',
            'rgba(245, 158, 11, 1)',
            'rgba(245, 158, 11, 1)',
            'rgba(245, 158, 11, 1)',
            'rgba(245, 158, 11, 1)'
          ],
          borderWidth: 0,
        barThickness: 10,
        }
      ],
      barDataOptions : {
        indexAxis: "x",
        plugins: {
          legend: {
            display: false,
            position: "top",
          },
          tooltip: {
            backgroundColor: 'white',
            titleColor: 'black',
            bodyColor: 'black',
            borderColor: 'rgba(0, 0, 0, 0.1)',
            borderWidth: 1
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
          },
          y: {
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
            grid: {
              color: "#D2D1D4",
            },
            border: {
              display: false,
              dash: [4, 4],
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        width: 300,
        height: 300,
        aspectRatio: 1,
      }
    }}, [dashboardData?.data?.topCountries]);


    const barDataCities = useMemo(() => {
      return {
      labels: dashboardData?.data?.topCities?.labels,
      datasets:[
        {
          label: "",
          data: dashboardData?.data?.topCities?.data,
          backgroundColor: [
            'rgba(245, 158, 11, 1)',
            'rgba(245, 158, 11, 1)',
            'rgba(245, 158, 11, 1)',
            'rgba(245, 158, 11, 1)',
            'rgba(245, 158, 11, 1)',
            'rgba(245, 158, 11, 1)',
            'rgba(245, 158, 11, 1)'
          ],
          borderWidth: 0,
        barThickness: dashboardData?.data?.topCities?.data?.length > 50 ? 7 : 10,
        }
      ],
      barDataCitiesOptions : {
        indexAxis: "x",
        plugins: {
          legend: {
            display: false,
            position: "top",
          },
          tooltip: {
            backgroundColor: 'white',
            titleColor: 'black',
            bodyColor: 'black',
            borderColor: 'rgba(0, 0, 0, 0.1)',
            borderWidth: 1
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
          },
          y: {
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
            grid: {
              color: "#D2D1D4",
            },
            border: {
              display: false,
              dash: [4, 4],
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        width: 300,
        height: 300,
        aspectRatio: 1,
      } 
    }}, [dashboardData?.data?.topCities]);

    const lineData = useMemo(() => {
      return {
        labels: dashboardData?.data?.streamsLineChart?.labels,
        datasets:
          dashboardData?.data?.streamsLineChart?.data?.map((item, index) => ({
            label: item.labels,
            data: item.data,
            backgroundColor: [
              "rgba(245, 158, 11, 0.2)",
              "rgba(217, 119, 6, 0.2)",
              "rgba(253, 186, 116, 0.2)",
            ],
            fill: true,
            borderColor: [
              "rgba(245, 158, 11, 1)",
              "rgba(217, 119, 6, 1)",
              "rgba(253, 186, 116, 1)",
            ],
            pointRadius: 10,
            pointHoverRadius: 15,
            pointBackgroundColor: "transparent",
            pointBorderColor: "transparent",
            barThickness: 10,
          })) || [],
          lineOptions: {
            plugins: {
              legend: {
                position: "bottom",
                align: "center",
                labels: {
                  boxWidth: 8,
                  boxHeight: 8,
                  borderColor: "transparent",
                  itemMargin: 5,
                  font: {
                    size: 12,
                  },
                },
              },
              tooltip: {
                backgroundColor: 'white',
                titleColor: 'black',
                bodyColor: 'black',
                borderColor: 'rgba(0, 0, 0, 0.1)',
                borderWidth: 1
              },
              layout: {
                padding: {
                  left: 0,
                  right: 10,
                  top: 0,
                  bottom: 50,
                },
              },
            },
            scales: {
              x: {
                display: true,
              title: {
                display: true
              },
                grid: {
                  display: false,
                },
                border: {
                  display: false,
                },
              },
              y: {
                ticks: {
                  beginAtZero: true,
                },
                grid: {
                  color: "#D2D1D4",
                },
                border: {
                  display: false,
                  dash: [4, 4],
                },
                display: true,
                title: {
                  display: true,
                  text: 'Views',
                  
                }
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            width: 300,
            height: 300,
            aspectRatio: 1,
          }
      };
    }, [dashboardData?.data?.streamsLineChart]);

    // const lineChartDataOptions = useMemo(() => {
    //   return {
    //     lineOptions: {
    //       plugins: {
    //         legend: {
    //           display: false
    //         },
    //         tooltip: {
    //           backgroundColor: 'white',
    //           titleColor: 'black',
    //           bodyColor: 'black',
    //           borderColor: 'rgba(0, 0, 0, 0.1)',
    //           borderWidth: 1,
    //         },
    //         layout: {
    //           padding: {
    //             left: 0,
    //             right: 10,
    //             top: 0,
    //             bottom: 50,
    //           },
    //         },
    //       },
    //       scales: {
    //         x: {
    //           stacked: true,
    //           grid: {
    //             display: false,
    //           },
    //           border: {
    //             display: false,
    //           },
    //         },
    //         y: {
    //           stacked: true,
    //           ticks: {
    //             beginAtZero: true,
    //           },
    //           grid: {
    //             color: "#D2D1D4",
    //           },
    //           border: {
    //             display: false,
    //             dash: [4, 4],
    //           },
    //         },
    //       },
    //       responsive: true,
    //       maintainAspectRatio: false,
    //       elements: {
    //         line: {
    //           tension: 0.4, // Smooth curves
    //           borderColor: "#ed6200", // Orange line
    //         },
    //       },
    //     }
        
    //   };
    // }, []);

    // const lineOptions = {
    //   plugins: {
    //     legend: {
    //       position: "bottom",
    //       align: "center",
    //       labels: {
    //         boxWidth: 8,
    //         boxHeight: 8,
    //         borderColor: "transparent",
    //         itemMargin: 5,
    //         font: {
    //           size: 12,
    //         },
    //       },
    //     },
    //     tooltip: {
    //       backgroundColor: 'white',
    //       titleColor: 'black',
    //       bodyColor: 'black',
    //       borderColor: 'rgba(0, 0, 0, 0.1)',
    //       borderWidth: 1
    //     },
    //     layout: {
    //       padding: {
    //         left: 0,
    //         right: 10,
    //         top: 0,
    //         bottom: 50,
    //       },
    //     },
    //   },
    //   scales: {
    //     x: {
    //       display: true,
    //     title: {
    //       display: true
    //     },
    //       grid: {
    //         display: false,
    //       },
    //       border: {
    //         display: false,
    //       },
    //     },
    //     y: {
    //       ticks: {
    //         beginAtZero: true,
    //       },
    //       grid: {
    //         color: "#D2D1D4",
    //       },
    //       border: {
    //         display: false,
    //         dash: [4, 4],
    //       },
    //       display: true,
    //       title: {
    //         display: true,
    //         text: 'Views',
            
    //       }
    //     },
    //   },
    //   responsive: true,
    //   maintainAspectRatio: false,
    //   width: 300,
    //   height: 300,
    //   aspectRatio: 1,
    // };


    const lineChartDataOptions = useMemo(() => {
      return {
        labels: chartDataNew?.data?.labels,
        datasets: [
          {
            label: "",
            data: chartDataNew?.data?.data?.data,
            borderWidth: 0,
            barThickness: chartDataNew?.data?.data?.data?.length > 50 ? 2 : 10,
            backgroundColor: "rgba(245, 158, 11, 1)",
          },
        ],
        lineOptions: {
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              backgroundColor: "white",
              titleColor: "black",
              bodyColor: "black",
              borderColor: "rgba(0, 0, 0, 0.1)",
              borderWidth: 1,
            },
            layout: {
              padding: {
                left: 0,
                right: 10,
                top: 0,
                bottom: 50,
              },
            },
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
            },
            y: {
              stacked: true,
              ticks: {
                beginAtZero: true,
              },
              grid: {
                color: "#D2D1D4",
              },
              border: {
                display: false,
                dash: [4, 4],
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          elements: {
            line: {
              tension: 0.4, // Smooth curves
              borderColor: "#ed6200", // Orange line
            },
            point: {
              radius: (context) => {
                // Check if there's only one data point
                const dataLength = context.chart.data.datasets[0].data.length;
                return dataLength === 1 ? 8 : 3; // Show point if only one item, hide otherwise
              },
              hoverRadius: (context) => {
                const dataLength = context.chart.data.datasets[0].data.length;
                return dataLength === 1 ? 10 : 1.4; // Larger radius on hover if only one item
              },
              spanGaps: true,
              borderWidth: 0,
              borderColor: "transparent",
              backgroundColor: "#ed6200",
            },
          },
        },
      };
    }, [chartDataNew?.data?.data]);

    const ageBarData = useMemo(() => {
      return {
      labels: dashboardData?.data?.ageCategoryBarChart?.labels,
      datasets: dashboardData?.data?.ageCategoryBarChart?.datasets,
      ageBarDataOptions : {
        indexAxis: "x",
        plugins: {
          legend: {
            position: "bottom",
            align: "center",
            labels: {
              boxWidth: 8,
              boxHeight: 8,
              borderColor: "transparent",
              itemMargin: 5,
              font: {
                size: 12,
              },
            },
          },
          tooltip: {
            backgroundColor: 'white',
            titleColor: 'black',
            bodyColor: 'black',
            borderColor: 'rgba(0, 0, 0, 0.1)',
            borderWidth: 1
          },
          layout: {
            padding: {
              left: 0,
              right: 10,
              top: 0,
              bottom: 50,
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
          },
          y: {
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
            grid: {
              color: "#D2D1D4",
            },
            border: {
              display: false,
              dash: [4, 4],
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        width: 300,
        height: 300,
        aspectRatio: 1,
      }  
    } }, [dashboardData?.data?.ageCategoryBarChart]);


    // const ageBarDataOptions = {
    //   indexAxis: "x",
    //   plugins: {
    //     legend: {
    //       position: "bottom",
    //       align: "center",
    //       labels: {
    //         boxWidth: 8,
    //         boxHeight: 8,
    //         borderColor: "transparent",
    //         itemMargin: 5,
    //         font: {
    //           size: 12,
    //         },
    //       },
    //     },
    //     tooltip: {
    //       backgroundColor: 'white',
    //       titleColor: 'black',
    //       bodyColor: 'black',
    //       borderColor: 'rgba(0, 0, 0, 0.1)',
    //       borderWidth: 1
    //     },
    //     layout: {
    //       padding: {
    //         left: 0,
    //         right: 10,
    //         top: 0,
    //         bottom: 50,
    //       },
    //     },
    //   },
    //   scales: {
    //     x: {
    //       stacked: true,
    //       grid: {
    //         display: false,
    //       },
    //       border: {
    //         display: false,
    //       },
    //     },
    //     y: {
    //       stacked: true,
    //       ticks: {
    //         beginAtZero: true,
    //       },
    //       grid: {
    //         color: "#D2D1D4",
    //       },
    //       border: {
    //         display: false,
    //         dash: [4, 4],
    //       },
    //     },
    //   },
    //   responsive: true,
    //   maintainAspectRatio: false,
    //   width: 300,
    //   height: 300,
    //   aspectRatio: 1,
    // };

    // const barDataOptions = {
    //   indexAxis: "x",
    //   plugins: {
    //     legend: {
    //       display: false,
    //       position: "top",
    //     },
    //     tooltip: {
    //       backgroundColor: 'white',
    //       titleColor: 'black',
    //       bodyColor: 'black',
    //       borderColor: 'rgba(0, 0, 0, 0.1)',
    //       borderWidth: 1
    //     },
    //   },
    //   scales: {
    //     x: {
    //       stacked: true,
    //       grid: {
    //         display: false,
    //       },
    //       border: {
    //         display: false,
    //       },
    //     },
    //     y: {
    //       stacked: true,
    //       ticks: {
    //         beginAtZero: true,
    //       },
    //       grid: {
    //         color: "#D2D1D4",
    //       },
    //       border: {
    //         display: false,
    //         dash: [4, 4],
    //       },
    //     },
    //   },
    //   responsive: true,
    //   maintainAspectRatio: false,
    //   width: 300,
    //   height: 300,
    //   aspectRatio: 1,
    // };

    // const barDataCitiesOptions = {
    //   indexAxis: "x",
    //   plugins: {
    //     legend: {
    //       display: false,
    //       position: "top",
    //     },
    //     tooltip: {
    //       backgroundColor: 'white',
    //       titleColor: 'black',
    //       bodyColor: 'black',
    //       borderColor: 'rgba(0, 0, 0, 0.1)',
    //       borderWidth: 1
    //     },
    //   },
    //   scales: {
    //     x: {
    //       stacked: true,
    //       grid: {
    //         display: false,
    //       },
    //       border: {
    //         display: false,
    //       },
    //     },
    //     y: {
    //       stacked: true,
    //       ticks: {
    //         beginAtZero: true,
    //       },
    //       grid: {
    //         color: "#D2D1D4",
    //       },
    //       border: {
    //         display: false,
    //         dash: [4, 4],
    //       },
    //     },
    //   },
    //   responsive: true,
    //   maintainAspectRatio: false,
    //   width: 300,
    //   height: 300,
    //   aspectRatio: 1,
    // };
  

  const doughnutData = {
    labels: dashboardData?.data?.genderPieChart?.labels,
    datasets: [
      {
        data: dashboardData?.data?.genderPieChart?.data,
        backgroundColor: [
          "rgba(245, 158, 11, 1)",
          "rgba(217, 119, 6, 1)",
          "rgba(253, 186, 116, 1)",
        ],
      },
    ],
  };
  
  
  const doughnutTextCenter = {
    id: 'doughnutTextCenter',
    afterDatasetsDraw(chart,args,plugins){
      const { ctx,doughnutData } = chart; 
      const centerX = chart.getDatasetMeta(0)?.data[0]?.x;
      const centerY = chart.getDatasetMeta(0)?.data[0]?.y;
      ctx.save();
      ctx.font = `bold 35px sans-serif`;
      ctx.fillStyle ='black';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle'
      ctx.fillText(dashboardData?.data?.genderPieChart?.total_count ? `${dashboardData?.data?.genderPieChart?.total_count}` : `0`, centerX, centerY);
    },
  };

  const semiDoughnutTextCenter = {
    id: 'semiDoughnutTextCenter',
    afterDatasetsDraw(chart,args,plugins){
      const { ctx,doughnutData } = chart; 
      const centerX = chart.getDatasetMeta(0)?.data[0]?.x;
      const centerY = chart.getDatasetMeta(0)?.data[0]?.y;
      ctx.save();
      ctx.font = `bold 35px sans-serif`;
      ctx.fillStyle ='black';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle'
      ctx.fillText(dashboardData?.data?.devicesPieChart?.total_count ? `${dashboardData?.data?.devicesPieChart?.total_count}` : `0`, centerX, centerY - 20);
    },
  };

  
  const deviceDoughnutData = {
    labels: dashboardData?.data?.devicesPieChart?.labels,
    //datasets: dashboardData?.data?.devicesPieChart?.datasets
    datasets: [
      {
        data: dashboardData?.data?.devicesPieChart?.data,
        backgroundColor: [
          "rgba(245, 158, 11, 1)",
           "rgba(217, 119, 6, 1)",
          "rgba(253, 186, 116, 1)",
        ],
      },
    ],
  };

  const deviceDoughnutOption = {
   plugins: {
      legend: {
        position: "bottom",
        align: "center",
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          borderColor: "transparent",
          itemMargin: 5,
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        backgroundColor: 'white',
        titleColor: 'black',
        bodyColor: 'black',
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1
      },
      layout: {
        padding: {
          left: 0,
          right: 10,
          top: 0,
          bottom: 50,
        },
      },
    },
   
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    circumference : 180,
    rotation: -90,
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    cutout:'65%',
    aspectRatio: 1,
  };

  const genderGraph = {
    plugins: {
      legend: {
        display: true,
        position: "right",
        align: "end",
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          font: {
            size: 10,
          },
        },
      },
      tooltip: {
        backgroundColor: 'white',
        titleColor: 'black',
        bodyColor: 'black',
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1
      },
      afterDraw: () => {},
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    cutout: '65%',
    aspectRatio: 1,
  };

  const navigation = [
    {
      label: "App Analytics",
      title: "App Analytics",
      link: `/home-dashboard?activeTab=app_analytics`,
      active: activeTab === "app_analytics",
    },
    {
      label: "Content Analytics",
      title: "Content Analytics",
      link: `/home-dashboard?activeTab=content_analytics`,
      active: activeTab === "content_analytics",
    }
  ];

  const [date, setDate] = useState({
    startDate: menuState.startDate,
    endDate: menuState.endDate,
    key: "selection",
  });

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];


    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
        state.filter_type = 1;
        state.data_type = 1;
      })
    );
   
  };

  // const ExportData = () => {
  //   try {
  //     let obj = {
  //   from_date: getFormatedDate(menuState?.startDate),
  //   to_date: getFormatedDate(menuState?.endDate),
  //   dashboard_type : activeTab === "app_analytics" ? 1 : 2  
  //     };
  //     if(activeTab != "app_analytics") {
  //       obj.program_sort_by = topProgramState.sortBy;
  //       obj.program_sort_order = topProgramState.sortOrder;
  //       obj.category_sort_by = topGenresState.sortBy;
  //       obj.category_sort_order = topGenresState.sortOrder;
  //       obj.program_media_type = topProgramState?.media_type;
  //       obj.category_media_type = topGenresState?.media_type;
  //     }

  //     exportAllData(obj).then((response) => {
  
  //       if (response?.status === 200) {
          
  //         toast.success("Success");

  //         let downloadLink = document.createElement("a");
  //         let blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

  //         downloadLink.href = window.URL.createObjectURL(blob);
  //         downloadLink.setAttribute(
  //           "download",
  //           `${activeTab}_${getFormatedDateRange(date?.startDate)} - ${getFormatedDateRange(date?.endDate)}.xlsx`
  //         );

  //         document.body.appendChild(downloadLink);
  //         downloadLink.click();
          
  //       } else if (response?.data?.error_code === 403) {
  //         toast.error(response?.data?.errors);
  //       } else if (response?.status_code === 500) {
  //         toast.error("Something Went Wrong");
  //       } 
  //     });
  //   } catch (error) {
  //     toast.error("Something went wrong");
  //   }
  // }
 

  const ExportData = (data, filteredTotals) => {
    try {
      if (!data || data.length === 0) {
        toast.error("No data available to export");
        return;
      }
  
      // Fields to remove
      const fieldsToRemove = ["program_id", "category_id", "episodes", "follow_growth", "thumbnail", "total_play_time", "type"];
  
      // Function to clean data by removing unwanted fields
      const cleanData = (items) => {
        return items.map((item) => {
          const newItem = { ...item };
          fieldsToRemove.forEach((field) => delete newItem[field]);
          return newItem;
        });
      };
  
      // Separate programs and categories
      const programs = cleanData(data.filter((item) => item.program_id));
      const categories = cleanData(data.filter((item) => item.category_id));
  
      // Get dynamic headers (excluding removed fields)
      const programHeaders = Object.keys(programs[0] || {});
      const categoryHeaders = Object.keys(categories[0] || {});
  
      // Function to format filteredTotals into CSV
      const formatFilteredTotals = (totals) => {
        return [
          `TOTAL PLAYS: ${filteredTotals.total_plays.total} (Growth: ${filteredTotals.total_plays.growth}), Data: ${filteredTotals.total_plays.data.map(item => `${item.labels}: ${item.value}`).join(", ")}`,
          `VIDEOS: ${totals.videos.total} (Current: ${totals.videos.current}), Growth: ${totals.videos.growth}`,
          `PODCASTS: ${totals.podcasts.total} (Current: ${totals.podcasts.current}), Growth: ${totals.podcasts.growth}`,
          `SHORTS: ${totals.shorts.total} (Current: ${totals.shorts.current}), Growth: ${totals.shorts.growth}`,
        ].join("\n");
      };
  
      // Generate CSV data for programs and categories
      const generateCSV = (headers, rows) => {
        return [
          headers.join(","), // Headers row
          ...rows.map((row) => headers.map((key) => row[key] || "-").join(",")), // Data rows
        ].join("\n");
      };
  
      const programCSV = generateCSV(programHeaders, programs);
      const categoryCSV = generateCSV(categoryHeaders, categories);
  
      // Prepare CSV content with totals included at the top
      const totalsCSV = formatFilteredTotals(filteredTotals);
  
      // Create a Blob for CSV export
      const blob = new Blob([`TOTALS\n${totalsCSV}\n\nPROGRAMS\n${programCSV.toUpperCase().replace(/_/g, " ")}\n\nCATEGORIES\n${categoryCSV.toUpperCase().replace(/_/g, " ")}`], { type: "text/csv;charset=utf-8;" });
  
      // Create download link
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.setAttribute("download", `${activeTab}_${getFormatedDateRange(menuState?.startDate)} - ${getFormatedDateRange(menuState?.endDate)}.csv`);
  
      // Trigger download
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
  
      toast.success("Export successful");
    } catch (error) {
      console.error("Error exporting data:", error);
      toast.error("Something went wrong during export");
    }
  };
  
  
  return {
    tabNavs: navigation,
    dashboardItems,
    dashboardData,
    isFetching,
    // children: dashboardItems[listingType]?.component,
    // listingType,
    doughnutData,
    genderGraph,
    doughnutTextCenter,
    semiDoughnutTextCenter,
    barData,
    // barDataOptions,
    filteredTotals,
    ageBarData,
    // ageBarDataOptions,
    lineData,
    // lineOptions,
    deviceDoughnutData,
    deviceDoughnutOption,
    handleDateRangeChange,
    date,
    activeTab,
    ExportData,
    barDataCities,
    // barDataCitiesOptions,
    fireBasedData,
    isFireBasedDataFetching,
    lineChartDataNew,
    menuState,
    appAnalyticsDashboardData,
    appAnalyticsFirebaseData,
    isFetchingAppAnalyticsFirebaseData,
    lineChartDataOptions,
    chartDataNew,
    isSuccess,
    combinedData,
  };
};

export default useHomeDashboardListing;
